'use client';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  HomepageHeroFragment,
  MicrocopyFragment,
} from '@graphql/generated-contentful/graphql';
import { useCookieSettings } from '@lib/hooks/useCookieSettings';
import { isDefined } from '@lib/util/isDefined';
import { isContentfulRichMicrocopy } from '@lib/util/microcopy';
import { toNonNullable } from '@lib/util/toNonNullable';
import { useRouter } from 'next/navigation';
import { ButtonStyle, HomepageHero as HomepageHeroUI } from 'ui-components';
import { useHeroCountdown } from './useHeroCountdown';

type HomepageHeroProps = {
  data: HomepageHeroFragment;
  position: number;
};

const translateCountdownLabels = (
  labelsMicrocopy: MicrocopyFragment[]
): Record<string, string> => {
  return labelsMicrocopy.reduce(
    (acc, microcopy) => {
      const key = microcopy?.key ? microcopy?.key?.split('.')[1] : '';
      acc[key] = microcopy?.value as string;
      return acc;
    },
    {} as Record<string, string>
  );
};

const HomepageHero = ({ data, position }: HomepageHeroProps) => {
  const {
    alignment,
    subheader,
    heading,
    description,
    countdownTo,
    countdownLabels,
    image,
    video,
    videoFallbackImage,
    ctaCollection,
    embeddedVideo,
    imageFocusArea,
    titleInternal,
    trackAsPromo,
  } = data || {};
  const imageUrl = image?.url;
  const imageDescription = image?.description;
  const videoUrl = video?.url || null;
  const { functionalCategoryEnabled, targetingCategoryEnabled } =
    useCookieSettings();
  const hasCookieConsent =
    targetingCategoryEnabled && functionalCategoryEnabled;

  const labels = translateCountdownLabels(
    countdownLabels?.microcopyCollection?.items.filter(isDefined).map(
      (
        microcopy
      ): {
        __typename?: 'Microcopy';
        key?: string | null;
        value?: string | null;
      } => {
        if (isContentfulRichMicrocopy(microcopy)) {
          return {
            key: microcopy.key,
            value: documentToHtmlString(microcopy.richValue?.json),
          };
        }
        return microcopy;
      }
    ) || []
  );

  const router = useRouter();
  const onExpiredCountdown = () => {
    router.refresh();
  };
  const { expired, hasCountdown, countdown } = useHeroCountdown(
    countdownTo,
    onExpiredCountdown
  );

  const showCountdown = hasCountdown && !expired;

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: titleInternal || '',
      typename: 'HomepageHero',
      position,
      creativeTitle: toNonNullable(image?.title || video?.title),
      trackAsPromo: trackAsPromo ?? false,
      ctas:
        ctaCollection?.items?.map((cta) => cta?.value).filter(isDefined) || [],
    });

  if (!heading || (!imageUrl && !videoUrl && !embeddedVideo)) {
    return null;
  }

  const actions = ctaCollection?.items
    ?.map((cta) => {
      return {
        label: cta?.value!,
        url: cta?.url!,
        openInNewTab: toNonNullable(cta?.openInNewTab),
        style:
          cta?.appearance === 'primary'
            ? ('primary' as ButtonStyle)
            : ('link' as ButtonStyle),
      };
    })
    .filter(isDefined);

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <HomepageHeroUI
        subHeader={subheader}
        title={heading}
        image={{
          url: imageUrl ?? '',
          alt: imageDescription ?? '',
          height: toNonNullable(image?.height),
          width: toNonNullable(image?.width),
        }}
        imageFocusArea={imageFocusArea}
        video={
          videoUrl
            ? {
                url: videoUrl,
                title: video?.title ?? '',
                description: video?.description ?? '',
                fallbackImage: videoFallbackImage?.url ?? '',
              }
            : undefined
        }
        embeddedVideo={
          embeddedVideo
            ? {
                url: embeddedVideo,
                fallbackImage: videoFallbackImage?.url ?? '',
                title: videoFallbackImage?.description ?? '',
              }
            : undefined
        }
        hasCookieConsent={hasCookieConsent}
        description={description ?? ''}
        countdownDuration={showCountdown ? countdown : undefined}
        countdownLabels={labels}
        actions={actions ?? []}
        align={alignment ? (alignment as 'left' | 'right' | 'center') : 'left'}
        onCtaClick={promoClickTrackingEvent}
      />
    </TrackPromotionImpression>
  );
};

export default HomepageHero;

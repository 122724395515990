'use client';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { CalloutFragment } from '@graphql/generated-contentful/graphql';
import { isDefined } from '@lib/util/isDefined';
import { toNonNullable } from '@lib/util/toNonNullable';
import { ButtonStyle } from 'ui-components/src/components/Button/types';
import { Callout as CalloutUI } from 'ui-components/src/components/Callout/Callout';

type CalloutProps = {
  data: CalloutFragment;
  position: number;
};

const Callout = ({ data, position }: CalloutProps) => {
  const {
    alignment,
    heading,
    description,
    image,
    ctaCollection,
    imageFocusArea,
    trackAsPromo,
    titleInternal,
  } = data || {};
  const align = alignment === 'Left' ? 'left' : 'right';
  const imageUrl = image?.url;
  const imageDescription = image?.description;

  const actions = ctaCollection?.items
    ?.map((cta) => {
      return {
        label: cta?.value!,
        url: cta?.url!,
        openInNewTab: toNonNullable(cta?.openInNewTab),
        style:
          cta?.appearance === 'primary'
            ? ('primary' as ButtonStyle)
            : ('secondary' as ButtonStyle),
      };
    })
    .filter(isDefined);

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: titleInternal || '',
      typename: 'Callout',
      position,
      creativeTitle: toNonNullable(image?.title),
      trackAsPromo: trackAsPromo ?? false,
      ctas: actions?.map((action) => action.label) || [],
    });

  if (!heading || !imageUrl) {
    return null;
  }

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <CalloutUI
        title={heading}
        image={{
          url: imageUrl,
          alt: imageDescription ?? '',
          height: toNonNullable(image?.height),
          width: toNonNullable(image?.width),
        }}
        imageFocusArea={imageFocusArea}
        description={description}
        actions={actions ?? []}
        align={align}
        onCtaClick={promoClickTrackingEvent}
      />
    </TrackPromotionImpression>
  );
};

export default Callout;

'use client';

import { useMicrocopy } from '@contexts/MicrocopyContext';
import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import { Stars } from '@ui-components/index';
import { interpolateStringWithData } from 'utils';

type ProductRatingProps = {
  rating: {
    average: number;
    count: number;
  };
};
export const ProductRating = ({ rating }: ProductRatingProps) => {
  const microcopy = useMicrocopy<{
    productCardMicrocopy: MicrocopyFragment[];
  }>();
  const ratingMicrocopy =
    microcopy?.productCardMicrocopy?.find(
      (microcopyFragment) =>
        microcopyFragment.key === 'product-card.star-rating-label'
    )?.value ?? 'Rating: {{rating}} out of 5 stars';

  const roundedRating = Math.round(rating.average * 10) / 10;
  return (
    <div className="tiny-text lg:body-small -mt-0.5 flex items-center">
      <Stars
        value={roundedRating}
        width={16}
        ariaLabel={interpolateStringWithData(ratingMicrocopy, {
          rating: roundedRating.toString(),
        })}
      />
      <div className="ml-1 text-grey-600" data-test="product-rating-count">
        ({rating.count})
      </div>
    </div>
  );
};
